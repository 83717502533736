import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { FaQuoteRight } from 'react-icons/fa';

import testimonialOne from '../images/testimonial/1.jpg';
import testimonialTwo from '../images/testimonial/2.jpg';
import testimonialThree from '../images/testimonial/3.jpg';



const Testimonial = () => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <div className="testimonial-area testimonial-style-1 pb--110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--100 mb_sm--50 mb_md--50">
                                <h3 className="title">What The AI Community Says <span className="bg">Testimonial</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <div className="testimonial-inner">
                                                <div className="icon">
                                                    <FaQuoteRight />
                                                </div>
                                                <p>Ernesto is a Software Developer with proficient algorithmic skills. I had the opportunity to exchange AI related Know-How as well as his opinion on available open source out-of-the-box solutions for a particular project of mine. His commitment on cracking on algorithms has brought him to consider AI development not just a profession but a way of living. His valuable experience can be crucial in any project development.</p>
                                                <div className="client-info">
                                                    <div className="thumbnail">
						   	<a href="https://www.linkedin.com/in/massimo-rusconi-1b3626174/" target="_blank">
                                                        	<img src={testimonialOne} alt="Testimonail Images" />
							</a>
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="title">Massimo Rusconi</h3>
                                                        <span>PhD-c Methods and Models for Economic Decisions- Department of Economics of Insubria University</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <div className="testimonial-inner">
                                                <div className="icon">
                                                    <FaQuoteRight />
                                                </div>
                                                <p>Ernesto and me studied and prepared some university projects together. He is a good colleague ready to look at the problem from different perspectives before deciding on the solution. His tenacity and willing to improve himself constantly are two important aspects of his character.</p>
                                                <div className="client-info">
                                                    <div className="thumbnail">
							<a href="https://www.linkedin.com/in/jesus-lago-63639053/" target="_blank">
                                                        	<img src={testimonialTwo} alt="Testimonail Images" />
							</a>
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="title">Jesus Lago</h3>
                                                        <span>Applied Scientist - Amazon</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <div className="testimonial-inner">
                                                <div className="icon">
                                                    <FaQuoteRight />
                                                </div>
                                                <p>The first time I met Ernesto was in France in the Applied Informatics section of an international Specialization on Advanced Robotics. We very often shared information on our projects back then. His curiosity on topics around his expertise allows him in my opinion to apply solutions from one domain into another one easily.</p>
                                                <div className="client-info">
                                                    <div className="thumbnail">
							<a href="https://www.linkedin.com/in/franz-a-van-horenbeke-3828226b/" target="_blank">
                                                        	<img src={testimonialThree} alt="Testimonail Images" />
							</a>
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="title">Franz A. Van-Horenbeke</h3>
                                                        <span>Research Assistant Human Robot Collaboration - Free University of Bozen-Bolzano</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                    </TabContent>
                                </div>

                                <div className="col-lg-6">
                                    <Nav className="rn-nav-image-item" tabs>
                                        <NavItem className="first-item">
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggle('1'); }}
                                                >
                                                    <img src={testimonialOne} alt="Testimonail Images" />
                                                    
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                            >
                                                <img src={testimonialTwo} alt="Testimonail Images" />
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggle('3'); }}
                                            >
                                                <img src={testimonialThree} alt="Testimonail Images" />
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
